frappe.provide("payments.ui");

import FloatingButton from "./floating_button";

payments.ui.SumUpButton = class SumUpButton extends FloatingButton {
	constructor({
		total,
		currency,
		title,
		mobile_no,
		email,
		reference_name,
		reference_doctype
	}) {
		super("SumUpButton", "sumup-button");
		this.total = total;
		this.currency = currency;
		this.title = title;
		this.mobile_no = mobile_no;
		this.email = email;
		this.reference_name = reference_name;
		this.reference_doctype = reference_doctype;
		this._listen_btn_click();
		this.update();
	}

	_listen_btn_click() {
		this.on("click", () => {
			payments.sumup.open_sumup_app({
				total: this.total,
				currency: this.currency,
				title: this.title,
				mobile_no: this.mobile_no,
				email: this.email,
				reference_name: this.reference_name,
				reference_doctype: this.reference_doctype
			})
		});
	}

	update() {
		this.set({ label: __("Pay with SumUp")});
		this.show();
	}

};

export default payments.ui.SumUpButton;
