frappe.provide("payments.sumup")

payments.sumup = {
	is_sumup_enabled: function() {
		return frappe.db.get_single_value("Sumup Settings", "enabled").then(r => Boolean(r))
	},
	open_sumup_app: async function({ total, currency, title, mobile_no, email, reference_name, reference_doctype }) {
		const url = await frappe.call({
			method: "payments.payment_gateways.doctype.sumup_settings.sumup_settings.get_sumup_app_link",
			args: {
				total: total,
				currency: currency,
				title: title,
				reference_name: reference_name,
				reference_doctype: reference_doctype,
				mobile_no: mobile_no,
				email: email,
			}
		})

		window.location = url.message.payment_app_url
	},
	supported_currencies: [
		"EUR",
		"GBP",
		"USD",
		"BRL",
		"CLP",
		"PLN",
		"CHF",
		"SEK",
		"CZK",
		"NOK",
		"DKK",
		"HUF",
		"BGN"
	]
}